<template>
  <b-card class="scrollme min-vh-100">
    <b-button
      v-b-toggle.create-attribute-collapse
      pill
      variant="outline-primary"
      size="sm"
      class="mb-1 ml-auto d-block"
    >
      Add new attribute
    </b-button>
    <b-collapse id="create-attribute-collapse" class="border mb-1 rounded shadow-sm p-1">
      <AddOrEditAttributes :refresh-table="getAllAttributes" />
    </b-collapse>
    <b-modal id="edit-attribute-modal" ref="edit-attribute-modal" size="lg" centered hide-footer>
      <AddOrEditAttributes :refresh-table="getAllAttributes" :is-edit="true" :attribute="attributeToEdit" @close="closeModal()"/>
    </b-modal>
    <b-table
      sticky-header
      hover
      :items="attributesList"
      :fields="fields"
      bordered
      responsive
      class="shadow-sm rounded"
      thead-tr-class="order-main-thead"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      small
    >
      <template #cell(actions)="data">
        <div class="d-flex align-items-center">
          <b-button variant="flat-primary" class="btn-icon p-0 mr-1" @click="handleAttributeEdit(data.item)">
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
          <b-button variant="flat-danger" class="btn-icon p-0" @click="handleAttributeDelete(data.item)">
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable, BButton, BCard, BCollapse, VBToggle, BModal } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import AddOrEditAttributes from "./components/AddOrEditAttributes.vue";

export default {
  components: {
    BTable,
    BButton,
    BCard,
    AddOrEditAttributes,
    BCollapse,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      popupIsOpen: false,
      createPopUpIsOpen: false,
      categoriesList: null,
      attributesList: null,
      attributeToEdit: null,
      fields: [
        {
          key: "actions",
          label: "Edit",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "nameGeo",
          label: "Name",
          formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
        },
        {
          key: "subCategoryId",
          label: "Sub Category",
          formatter: (value) => this.subCategoryById(value),
        },
        {
          key: "isConfigurable",
          label: "Configurable",
          formatter: (value, key, item) => (item.isConfigurable ? "Yes" : "No"),
        },
      ],
    };
  },
  computed: {
    ...mapState("parametersModule", ["categories", "allAttributes", "subCategories"]),
  },
  watch: {
    // subCategories(newVal) {
    // 	console.log('subcategories', newVal);
    // },
    categories(newVal) {
      this.categoriesList = newVal.data.productCategories;
      // console.log("categories ", this.categoriesList);
    },
    allAttributes(newVal) {
      this.attributesList = newVal.productAttributes;
      // console.log("attributes ", this.attributesList);
    },
  },
  created() {
    this.getSubCategories().then(() => {
      // console.log("subcategories", this.subCategories);
    });
    this.getCategories({
      skip: 0,
      take: 100,
      searchKey: "",
      id: "",
    });
    this.getAllAttributes();
  },
  methods: {
    ...mapActions("parametersModule", ["getSubCategories", "getCategories", "getAllAttributes", "deleteAttribute"]),
    subCategoryById(id) {
      // console.log("hit");
      const subCat = this.subCategories.data.productSubCategories.find((x) => x.productSubCategoryId === id);

      return subCat ? `${subCat.productSubCategoryNameGeo} / ${subCat.productSubCategoryNameEng}` : "";
    },
    onClickAttributeRow(e) {
      this.popupIsOpen = !this.popupIsOpen;
      this.getCategory({ id: e.productCategoryId });
    },
    onRowClicked(e) {
      this.handleAttributeEdit(e);
    },
    showCreatePopUp() {
      this.createPopUpIsOpen = !this.createPopUpIsOpen;
    },
    handleAttributeDelete(e) {
      console.log("e", e)
      Swal.fire({
        title: `Are you sure you want to delete attribute - ${e.nameGeo} / ${e.nameEng}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAttribute(e.id).then(() => {
            this.getAllAttributes();
          });
        }
      });
    },
    handleAttributeEdit(e) {
      this.attributeToEdit = e;
      this.$bvModal.show("edit-attribute-modal");
    },
    closeModal() {
      this.$bvModal.hide("edit-attribute-modal");
    }
  },
};
</script>

<style lang="scss" scoped>
.scrollme {
  overflow-x: auto;
}
</style>
